<template>
    <div>
        <b-tabs content-class="pt-5 position-relative" class="line-tabs" v-model="tabIndex">
            <div class="tabs-bottom-line"></div>
            <ValidationObserver ref="formModalValidate">
                <b-tab :title="$t('staff_information')" active>
                    <b-row class="mt-2">
                        <b-col cols="12" md="4">
                            <b-card :header="$t('personal_information')" class="soft mb-5">
                                <ValidationProvider name="name"
                                                    rules="required"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('first_name')">
                                        <b-form-input v-model="formData.name"
                                                      :placeholder="$t('first_name')"
                                                      :state="errors[0] ? false : null"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="surname"
                                                    rules="required"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('last_name')">
                                        <b-form-input v-model="formData.surname"
                                                      :placeholder="$t('last_name')"
                                                      :state="errors[0] ? false : null"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="username"
                                                    rules="required"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('username')">
                                        <b-form-input v-model="formData.username"
                                                      :placeholder="$t('username')"
                                                      :state="errors[0] ? false : null"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="gender"
                                                    rules="required"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('gender')">
                                        <gender-selectbox type="english"
                                                          v-model="formData.gender"
                                                          :validate-error="errors[0]"
                                                          :state="errors[0] ? false : null"/>
                                    </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="nationality_code"
                                                    rules="required"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('nationality')">
                                        <country-selectbox valueType="code"
                                                           v-model="formData.nationality_code"
                                                           :placeholder="$t('nationality')"
                                                           :state="errors[0] ? false : null"
                                                           :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="birthdate"
                                                    rules=""
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('date_of_birth')">
                                        <b-form-input type="date"
                                                      v-model="formData.birthdate"
                                                      :placeholder="$t('date_of_birth')"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-card>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-card :header="$t('university_informations')" class="soft mb-5">
                                <ValidationProvider name="faculty_code"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('faculty')">
                                        <faculty-selectbox v-model="formData.faculty_code"
                                                           :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="program_code"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('program')">
                                        <program-selectbox v-model="formData.program_code"
                                                           :faculty_code="formData.faculty_code"
                                                           :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="primary_area_of_study"
                                                    rules=""
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('primary_area_of_study')">
                                        <parameter-selectbox v-model="formData.primary_area_of_study"
                                                             code="primary_area_of_studies"
                                                             :validateError="errors[0]"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="title"
                                                    rules=""
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('instructor_type')">
                                        <parameter-selectbox v-model="formData.title"
                                                             code="titles"
                                                             :validateError="errors[0]"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-card>
                            <b-card :header="$t('academic_degree')" class="soft mb-5">
                                <ValidationProvider
                                    name="highest_academic_degree"
                                    rules=""
                                    v-slot="{ valid, errors }"
                                >
                                    <b-form-group :label="$t('highest_academic_degree')">
                                        <parameter-selectbox
                                            v-model="formData.highest_academic_degree"
                                            code="highest_academic_degrees"
                                            :validateError="errors[0]"
                                        />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                    </b-form-group>
                                    <b-form-group :label="$t('degree_year')" v-if="formData.highest_academic_degree">
                                        <b-form-input
                                            v-model="formData.degree_year"
                                        />
                                    </b-form-group>
                                    <b-form-group :label="$t('degree_country')" v-if="formData.highest_academic_degree">
                                        <country-selectbox valueType="code"
                                                           v-model="formData.degree_country"
                                                           :state="errors[0] ? false : null"
                                                           :validate-error="errors[0]"/>
                                    </b-form-group>
                                    <b-form-group :label="$t('issuing')" v-if="formData.highest_academic_degree">
                                        <b-form-input
                                            v-model="formData.issuing_institution"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-card>
                        </b-col>
                        <b-col cols="12" md="4" >
                            <b-card :header="$t('other_information')" class="soft mb-5">
                                <ValidationProvider name="working_status"
                                                    rules="required"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('status')">
                                        <parameter-selectbox code="working_statuses"
                                                             type="english"
                                                             v-model="formData.working_status"
                                                             :validate-error="errors[0]"
                                                             :state="errors[0] ? false : null"/>

                                    </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="start_date"
                                                    rules="required"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('start_date')">
                                        <select-date v-model="formData.start_date"
                                                     :validation-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="end_date"
                                                    rules="required"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('end_date')">
                                        <select-date v-model="formData.end_date"
                                                     :validation-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-card>
                        </b-col>
                    </b-row>
                    <div class="d-flex justify-content-center mt-3 mb-3">
                        <b-button variant="primary"
                                  @click="updateForm"
                                  :disabled="formLoading">
                            {{ $t('save') }}
                        </b-button>
                    </div>
                </b-tab>
                <b-tab :title="$t('contact_information')">
                    <b-row class="mt-2">
                        <b-col cols="12" md="4">
                            <ValidationProvider name="email"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('bi_email')">
                                    <b-form-input v-model="formData.email"
                                                  type="email"
                                                  :placeholder="$t('bi_email')"
                                                  readonly
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider name="bi_email"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('email')">
                                    <b-form-input v-model="formData.bi_email"
                                                  type="email"
                                                  :placeholder="$t('email')"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider name="mobile_tel"
                                                maxlength="11"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('mobile_number')">
                                    <b-form-input v-model="formData.mobile_tel"
                                                  :placeholder="$t('gsm_no')"
                                                  v-mask="'(+49)-##-########'"
                                                  placeholder="(+49)-##-########"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider name="address" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('address')">
                                    <b-form-textarea
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        v-model="formData.address"></b-form-textarea>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <div class="d-flex justify-content-center mt-3 mb-3">
                        <b-button variant="primary"
                                  @click="updateForm"
                                  :disabled="formLoading">
                            {{ $t('save') }}
                        </b-button>
                    </div>
                </b-tab>
            </ValidationObserver>
        </b-tabs>
    </div>
</template>

<script>
    // Components
    import CountrySelectbox from '@/components/interactive-fields/CountrySelectbox';
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
    import GenderSelectbox from '@/components/interactive-fields/GenderSelectbox';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox';

    // Services
    import StaffService from '@/services/StaffService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import SelectDate from "@/components/interactive-fields/SelectDate";

    export default {
        components: {
            SelectDate,
            CountrySelectbox,
            FacultySelectbox,
            GenderSelectbox,
            ParameterSelectbox,
            ProgramSelectbox,
            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                tabIndex: 0,
                formData: {
                    // Tab 1
                    name: null,
                    surname: null,
                    birthdate: null,
                    nationality_code: null,
                    gender: null,

                    highest_academic_degree: null,
                    primary_area_of_study: null,
                    username: null,
                    faculty_code: null,
                    program_code: null,

                    degree_year: null,
                    degree_country: null,
                    issuing_institution: null,

                    title: null,
                    working_status: null,
                    start_date: null,
                    end_date: null,

                    // Tab 2
                    email: null,
                    bi_email: null,
                    mobile_tel: null,
                    address: null,

                },
                formLoading: false
            };
        },
        watch: {
            formId: function(val) {
                this.get(val)
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                StaffService.get(id)
                            .then(response => {
                                let data = response.data.data;
                                this.formData.registration_number = data.registration_number
                                this.formData.title = data.title
                                if (data.person) {
                                    this.formData.name = data.person.name
                                    this.formData.surname = data.person.surname
                                    this.formData.birthdate = data.person.birthdate
                                    this.formData.nationality_code = data.person.nationality_code
                                    this.formData.gender = data.person.gender
                                    this.formData.username = data.person.username
                                }
                                this.formData.primary_area_of_study = data.primary_area_of_study
                                this.formData.faculty_code = data.faculty_code
                                this.formData.program_code = data.program_code
                                this.formData.title = data.title
                                this.formData.working_status = data.working_status
                                this.formData.start_date = data.start_date
                                this.formData.end_date = data.end_date
                                this.formData.bi_email = data.bi_email
                                this.formData.highest_academic_degree = data.highest_academic_degree
                                this.formData.degree_year = data.degree_year
                                this.formData.degree_country = data.degree_country
                                this.formData.issuing_institution = data.issuing_institution

                                if (data.person) {
                                    this.formData.email = data.person.email
                                    this.formData.mobile_tel = data.person.mobile_tel
                                    this.formData.address = data.person.address
                                }
                            })
                            .catch(e => {
                                this.showErrors(e)
                            })
            },

            // Section updateForm
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    let formData = {
                        ...this.formData
                    }
                    formData.start_date = formData.start_date ? formData.start_date : null;
                    formData.end_date = formData.end_date ? formData.end_date : null;
                    formData.birthdate = formData.birthdate ? formData.birthdate : null;

                    StaffService.update(this.formId, formData)
                                .then(response => {
                                    this.$emit('updateFormSuccess', true);
                                    this.$toast.success(this.$t('api.' + response.data.message));
                                })
                                .catch(e => {
                                    if (e.status == '422') {
                                        let errors = e.data.errors;
                                        if (errors.name ||
                                            errors.surname ||
                                            errors.birthdate ||
                                            errors.nationality_code ||
                                            errors.gender ||
                                            errors.highest_academic_degree ||
                                            errors.degree_year ||
                                            errors.degree_country ||
                                            errors.issuing_institution ||
                                            errors.primary_area_of_study ||
                                            errors.username ||
                                            errors.faculty_code ||
                                            errors.program_code ||
                                            errors.title ||
                                            errors.working_status ||
                                            errors.start_date ||
                                            errors.end_date
                                        ) {
                                            this.tabIndex = 0;
                                        }
                                        else {
                                            this.tabIndex = 1;
                                        }
                                    }
                                    this.showErrors(e, this.$refs.formModalValidate);
                                })
                                .finally(() => {
                                    this.formLoading = false;
                                })
                }
                else {
                    let errors = this.$refs.formModalValidate.errors;
                    if (errors.name.length ||
                        errors.surname.length ||
                        errors.birthdate.length ||
                        errors.nationality_code.length ||
                        errors.gender.length ||
                        errors.highest_academic_degree.length ||
                        errors.degree_year.length ||
                        errors.degree_country.length ||
                        errors.issuing_institution.length ||
                        errors.primary_area_of_study.length ||
                        errors.username.length ||
                        errors.faculty_code.length ||
                        errors.program_code.length ||
                        errors.title.length ||
                        errors.working_status.length ||
                        errors.start_date.length ||
                        errors.end_date.length
                    ) {
                        this.tabIndex = 0;
                    }
                    else {
                        this.tabIndex = 1;
                    }
                }
            }
        }
    }
</script>
