<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('staff')"
                        :isNewButton="checkPermission('staff_store')"
                        :isColumns="true"
                        @new-button-click="createFormShow()"
                        @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div class="mb-1"
                             v-for="(column, key) in datatable.columns"
                             v-bind:key="key">
                            <b-form-checkbox v-model="column.hidden"
                                             :id="'checkbox-' + key"
                                             :name="'checkbox-' + key"
                                             :value="false"
                                             :unchecked-value="true"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('staff')"
                              :isNewButton="checkPermission('staff_store')"
                              :isColumns="true"
                              @new-button-click="createFormShow()"
                              @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div class="mb-1"
                             v-for="(column, key) in datatable.columns"
                             v-bind:key="key">
                            <b-form-checkbox v-model="column.hidden"
                                             :id="'checkbox-' + key"
                                             :name="'checkbox-' + key"
                                             :value="false"
                                             :unchecked-value="true"
                                             v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>

            <!-- Datatable -->
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
                :export-excel="checkPermission('staff_excelexport')"
                @exportExcel="downloadExcel">
                <b-row>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('first_name')">
                            <b-form-input type="text"
                                          v-model="datatable.queryParams.filter.name"
                                          :placeholder="$t('first_name')"/>
                        </b-form-group>
                        <b-form-group :label="$t('last_name')">
                            <b-form-input type="text"
                                          v-model="datatable.queryParams.filter.surname"
                                          :placeholder="$t('last_name')"/>
                        </b-form-group>
                        <b-form-group :label="$t('email')">
                            <b-form-input type="email"
                                          v-model="datatable.queryParams.filter.email"
                                          :placeholder="$t('email')"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"/>
                        </b-form-group>
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                               :faculty_code="datatable.queryParams.filter.faculty_code"
                            />
                        </b-form-group>
                            <b-form-group :label="$t('instructor_type')">
                                <parameter-selectbox v-model="datatable.queryParams.filter.title"
                                                     code="titles"/>
                            </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('role')">
                            <role-selectbox value-type="name"
                                            v-model="datatable.queryParams.filter.roles"/>
                        </b-form-group>
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox code="working_statuses"
                                                 v-model="datatable.queryParams.filter.working_status"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>

            <!-- Create Form -->
            <CommonModal ref="createFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('new') }}
                </template>
                <template v-slot:CommonModalContent>
                    <CreateForm @createFormSuccess="createFormSuccess"
                                v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>

            <!-- Update Form-->
            <CommonModal ref="updateFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit') }}
                </template>
                <template v-slot:CommonModalContent>
                    <UpdateForm :formId="formId"
                                @updateFormSuccess="updateFormSuccess"
                                v-if="formProcess=='update'"/>
                </template>
            </CommonModal>

            <!-- Authorization Form-->
            <CommonModal ref="authorizationFormModal" size="xxl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('authorizations') }}
                </template>
                <template v-slot:CommonModalContent>
                    <AuthorizationForm :userId="userId"
                                       v-if="formProcess=='authorization' && userId"
                                       @authorizationFormSuccess="authorizationFormSuccess"
                    />
                </template>
            </CommonModal>

        </app-layout>
    </div>
</template>

<script>
    // Template
    import AppLayout from '@/layouts/AppLayout';
    import Header from '@/layouts/AppLayout/Header';
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile';

    // Components
    import DatatableFilter from '@/components/datatable/DatatableFilter';
    import Datatable from '@/components/datatable/Datatable';
    import CommonModal from '@/components/elements/CommonModal';

    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox';
    import RoleSelectbox from '@/components/interactive-fields/RoleSelectbox';

    // Pages
    import CreateForm from './CreateForm';
    import UpdateForm from './UpdateForm';
    import AuthorizationForm from './AuthorizationForm';

    // Services
    import StaffService from '@/services/StaffService';

    // Others
    import qs from 'qs';

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,

            DatatableFilter,
            Datatable,
            CommonModal,

            FacultySelectbox,
            ParameterSelectbox,
            ProgramSelectbox,
            RoleSelectbox,

            CreateForm,
            UpdateForm,
            AuthorizationForm
        },
        metaInfo() {
            return {
                title: this.$t('staff')
            };
        },
        data() {
            return {
                classrooms: [],
                campus_id: null,
                building_id: null,
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('authorizations'),
                                    class: 'ri-shield-star-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "assignrole_show",
                                    callback: (row) => {
                                        this.authorizationFormShow(row.user_id, row.id);
                                    }
                                },
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "staff_update",
                                    callback: (row) => {
                                        this.updateFormShow(row.id);
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "staff_delete",
                                    callback: (row) => {
                                        this.deleteForm(row.id);
                                    }
                                },
                                {
                                    text: this.$t('remind_password'),
                                    class: 'ri-key-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "person_passwordreminder",
                                    callback: ({user_id}) => {
                                        this.getNewPassword(user_id);
                                    }
                                }

                            ]
                        },
                        {
                            label: 'ID',
                            hidden: true,
                            field: 'user_id'
                        },
                        {
                            label: this.toUpperCase('name_surname'),
                            field: 'person.name',
                            hidden: false,
                            formatFn: function(value, col) {
                                return col.person.name + ' ' + col.person.surname;
                            },
                            sortable: false
                        },
                        {
                            label: this.toUpperCase('bi_email'),
                            field: 'person.email',
                            hidden: false,
                            formatFn: function(value, col) {
                                return col.person.email;
                            },
                            sortable: false
                        },
                        {
                            label: this.toUpperCase('mobile_number'),
                            field: 'person.mobile_tel',
                            hidden: false,
                            formatFn: function(value, col) {
                                return col.person.mobile_tel;
                            },
                            sortable: false
                        },
                        {
                            label: this.toUpperCase('faculty'),
                            field: ('faculty_name'),
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.toUpperCase('program'),
                            field: ('program_name'),
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.toUpperCase('status'),
                            field: ('status'),
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.toUpperCase('roles'),
                            field: ('roles_explanation'),
                            hidden: false,
                            sortable: false
                        }
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: '-id',
                        page: 1,
                        limit: 20
                    }
                },
                formProcess: null,
                formId: null,
                userId: null
            }
        },
        created() {
            this.filterSet();
            this.formClear();
        },
        methods: {
            filterSet() {
                this.datatable.queryParams.filter = {
                    name: null,
                    surname: null,
                    email: null,
                    faculty_code: null,
                    title: null,
                    program_code: null,
                    working_status: null,
                    roles: null
                };
            },
            filterClear() {
                this.filterSet();
                this.getRows();
            },
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            changeColumn(key) {
                this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field;
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return StaffService.getAll(config)
                                   .then((response) => {
                                       this.datatable.rows = response.data.data;
                                       this.datatable.total = response.data.pagination.total;
                                   })
                                   .finally(() => {
                                       this.datatable.isLoading = false;
                                   });
            },

            // Clear
            formClear() {
                this.formId = null;
                this.formProcess = null;
            },

            // Create
            createFormShow() {
                this.formId = null;
                this.formProcess = 'create';
                this.$refs.createFormModal.$refs.commonModal.show();
            },
            createFormSuccess() {
                this.$refs.createFormModal.$refs.commonModal.hide();
                this.getRows();
                this.formClear();
            },

            // Update
            updateFormShow(id) {
                this.formId = id;
                this.formProcess = 'update';
                this.$refs.updateFormModal.$refs.commonModal.show();
            },
            updateFormSuccess() {
                this.$refs.updateFormModal.$refs.commonModal.hide();
                this.getRows();
                this.formClear();
            },

            // Authorization
            authorizationFormShow(userId, id) {
                this.userId = userId

                this.formId = id
                this.formProcess = 'authorization'
                this.$refs.authorizationFormModal.$refs.commonModal.show();
            },
            authorizationFormSuccess() {
                this.$refs.authorizationFormModal.$refs.commonModal.hide();
                this.getRows();
                this.formClear();
            },

            // Delete
            deleteForm(id) {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            StaffService.del(id)
                                        .then(response => {
                                            this.$toast.success(this.$t('api.' + response.data.message));
                                            this.getRows();
                                            this.formClear();
                                        })
                                        .catch(error => {
                                            this.$toast.error(this.$t('api.' + error.data.message));
                                        })
                        }
                    })
            },
            downloadExcel(){
                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                StaffService.downloadAsEXcel(config)
                    .then(res=>this._downloadFile(res,this.$t('staff')+'.xlsx'))
                    .catch(err=>this.showErrors(err))
            },
            getNewPassword(userID){
                StaffService.getNewPassword(userID)
                    .then((res)=>{this.$toast.success(this.$t('api.' + res.data.message));})
                    .catch(err=>this.showErrors(err))
            }
        }
    }
</script>

